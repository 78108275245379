import { AxiosResponse } from "axios";
import { createContext } from "react";
import { LogingRequest, LoginResponse } from "../actions/types";

export type AuthContextType = {
  user: any;
  setUser: (user: any) => void;
  login: (request: LogingRequest) => Promise<AxiosResponse<LoginResponse, any>>;
  forgotPassword: (email: string) => Promise<AxiosResponse<any, any>>;
  cleanUp: () => void;
};

export const AuthContext = createContext<AuthContextType>({
  user: null,
  setUser: (user: any) => {},
  login: (request: LogingRequest) => null,
  forgotPassword: (email: string) => null,
  cleanUp: () => {},
});
