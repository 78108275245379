import axios from "axios";
import { createClient } from "../util/axios-helpers";
import { endpoint, getQueryStringParams } from "../util/http-helpers";
import { LoginResponse, UserDetailsResponse } from "./types";

export const login = ({
  username,
  password,
}: {
  username: string;
  password: string;
}) => {
  const client = createClient(axios);

  return client.post<LoginResponse>(endpoint("api/v1/users/login"), {
    username,
    password,
  });
};

export const getUserDetails = () => {
  const client = createClient(axios);

  return client.get<UserDetailsResponse>(endpoint(`api/v1/me/user`));
};

export const searchForUsers = (
  partnerId: string,
  firstName: string,
  username: string,
  role: string,
  pageSize: number,
  pageIndex: number,
  sortBy: string
) => {
  const client = createClient(axios);

  const params = getQueryStringParams({
    partnerId,
    firstName,
    username,
    role,
    pageSize,
    pageNumber: pageIndex,
    sortBy,
  });

  return client.get(endpoint(`api/v1/users?${params}`));
};

export const createUser = (user: any) => {
  const client = createClient(axios);

  return client.post(endpoint(`api/v1/users`), user);
};

export const forgotPassword = (email: string) => {
  const client = createClient(axios);

  return client.post(endpoint("api/v1/users/forgot-password"), {
    username: email,
  });
};

export const validateMagicLink = async (magicCode: string) => {
  const client = createClient(axios);

  try {
    const response = await client.post(
      endpoint("api/v1/users/validate-magic-link"),
      {
        Code: magicCode,
      }
    );

    if (response.status === 200) {
      return { success: true, data: response.data };
    }

    return {
      success: false,
      message: response.data.message || "Failed to validate the Magic Link",
    };
  } catch (error) {
    console.error("Error while validating the Magic Link:", error);
    return { success: false, message: "Network or server error." };
  }
};
