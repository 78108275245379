import React, { useState } from "react";
import {
  Card,
  CardBody,
  CardTitle,
  Row,
  Col,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Snackbar,
  TextField,
} from "@mui/material";
import { ExpandMore, Delete, Edit } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import { useSnack } from "../../../../hooks/useSnack";

function ClientDealsServices() {
  const [deals, setDeals] = useState([]);
  const [modalOpen, setModalOpen] = useState(false);
  const [confirmDeleteIndex, setConfirmDeleteIndex] = useState(null);
  const [editIndex, setEditIndex] = useState(null);
  const [newDeal, setNewDeal] = useState({
    title: "",
    description: "",
    priceFrom: "",
    priceActual: "",
    photoUrl: "",
    category: "Service",
    productCode: "",
  });
  const snack = useSnack();

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewDeal((prev) => ({ ...prev, [name]: value }));
  };

  const addOrUpdateDeal = () => {
    if (!newDeal.title || !newDeal.description || !newDeal.priceActual) {
      snack.setMessage("Please fill in all required fields");
      snack.onOpen();
      return;
    }
    
    if (editIndex !== null) {
      const updatedDeals = deals.map((deal, index) => (index === editIndex ? newDeal : deal));
      setDeals(updatedDeals);
      setEditIndex(null);
    } else {
      setDeals([...deals, newDeal]);
    }
    
    setNewDeal({
      title: "",
      description: "",
      priceFrom: "",
      priceActual: "",
      photoUrl: "",
      category: "Service",
      productCode: "",
    });
    setModalOpen(false);
  };

  const editDeal = (index) => {
    setNewDeal(deals[index]);
    setEditIndex(index);
    setModalOpen(true);
  };

  const confirmDeleteDeal = (index) => {
    setConfirmDeleteIndex(index);
  };

  const removeDeal = () => {
    setDeals(deals.filter((_, i) => i !== confirmDeleteIndex));
    setConfirmDeleteIndex(null);
  };

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMore />}>
        <span className="h2 mb-3">Client Deals & Services</span>
      </AccordionSummary>
      <AccordionDetails>
        <Row>
            {deals.length > 0 ? <Col lg={12}>
                <Box className="h3 mb-4 justify-content-between align-items-center">
                    <Button color="primary" className="mb-2" style={{ float: 'right' } } onClick={() => { setModalOpen(true); setEditIndex(null); }}>
                        Add New Deal
                    </Button>
                </Box>
            </Col> : null}
            <Col lg={12}>
                <Card>
                    <CardBody>
                        <Row>
                            {deals.length === 0 ? (
                                <Col md={12} className="text-center py-5">
                                    <h4>No deals registered</h4>
                                    <p>Start by adding a new deal.</p>
                                    <Button color="primary" onClick={() => { setModalOpen(true); setEditIndex(null); }}>
                                        Add New Deal
                                    </Button>
                                </Col>
                            ) : (
                                deals.map((deal, index) => (
                                    <Col md={4} key={index} className="mb-4">
                                        <Card>
                                            {deal.photoUrl && (
                                                <img
                                                    src={deal.photoUrl}
                                                    alt="Deal Cover"
                                                    style={{ width: "100%", height: "200px", objectFit: "cover", borderTopLeftRadius: "8px", borderTopRightRadius: "8px" }}
                                                />
                                            )}
                                            <CardBody>
                                                <h5>{deal.title}</h5>
                                                <p>{deal.category}</p>
                                                <p><strong>Price:</strong> ${deal.priceActual}</p>
                                                <Button color="secondary" size="sm" className="me-2" onClick={() => editDeal(index)}>
                                                    <Edit />
                                                </Button>
                                                <Button color="danger" size="sm" onClick={() => confirmDeleteDeal(index)}>
                                                    <Delete />
                                                </Button>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                ))
                            )}
                        </Row>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        
      </AccordionDetails>
      <Snackbar open={snack.isOpen} autoHideDuration={3000} onClose={snack.onClose} message={snack.message} />

      <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} size="lg">
        <ModalHeader toggle={() => setModalOpen(!modalOpen)}>{editIndex !== null ? "Edit Deal" : "Add New Deal"}</ModalHeader>
        <ModalBody>
          <Row className="gy-4">
            <Col md={6} className="mb-3">
              <TextField label="Title" name="title" value={newDeal.title} onChange={handleInputChange} fullWidth />
            </Col>
            <Col md={6} className="mb-3">
              <TextField label="Description" name="description" value={newDeal.description} onChange={handleInputChange} fullWidth />
            </Col>
            <Col md={6} className="mb-3">
              <NumericFormat
                label="Price From"
                name="priceFrom"
                value={newDeal.priceFrom}
                onValueChange={(values) => setNewDeal((prev) => ({ ...prev, priceFrom: values.value }))}
                thousandSeparator="," 
                prefix="$"
                customInput={TextField}
                fullWidth
              />
            </Col>
            <Col md={6} className="mb-3">
              <NumericFormat
                label="Price Actual"
                name="priceActual"
                value={newDeal.priceActual}
                onValueChange={(values) => setNewDeal((prev) => ({ ...prev, priceActual: values.value }))}
                thousandSeparator="," 
                prefix="$"
                customInput={TextField}
                fullWidth
              />
            </Col>
            <Col md={6} className="mb-3">
              <TextField label="Photo URL" name="photoUrl" value={newDeal.photoUrl} onChange={handleInputChange} fullWidth />
            </Col>
            <Col md={6} className="mb-3">
              <FormControl fullWidth>
                <InputLabel>Category</InputLabel>
                <Select name="category" value={newDeal.category} onChange={handleInputChange}>
                  <MenuItem value="Service">Service</MenuItem>
                  <MenuItem value="Entertainment">Entertainment</MenuItem>
                  <MenuItem value="Restaurant">Restaurant</MenuItem>
                  <MenuItem value="Others">Others</MenuItem>
                </Select>
              </FormControl>
            </Col>
            {newDeal.photoUrl && (
              <Col md={12} className="d-flex justify-content-center mt-4">
                <img src={newDeal.photoUrl} alt="Preview" style={{ maxWidth: "100%", maxHeight: "200px", borderRadius: "8px" }} />
              </Col>
            )}
          </Row>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setModalOpen(false)}>Cancel</Button>
          <Button color="primary" onClick={addOrUpdateDeal}>{editIndex !== null ? "Update Deal" : "Save Deal"}</Button>
        </ModalFooter>
      </Modal>

      <Modal isOpen={confirmDeleteIndex !== null} toggle={() => setConfirmDeleteIndex(null)}>
        <ModalHeader toggle={() => setConfirmDeleteIndex(null)}>Confirm Delete</ModalHeader>
        <ModalBody>Are you sure you want to delete this deal?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={() => setConfirmDeleteIndex(null)}>Cancel</Button>
          <Button color="danger" onClick={removeDeal}>Delete</Button>
        </ModalFooter>
      </Modal>
    </Accordion>
  );
}

export default ClientDealsServices;
