import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { validateMagicLink } from "../../../actions/user-actions";
import { useAuth } from "../../../hooks/useAuth";

const MagicLinkPage = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const navigate = useNavigate();
  const { authenticate } = useAuth();

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const magicCode = urlParams.get("code");

    if (!magicCode) {
      setErrorMessage("Magic link not found.");
      setTimeout(() => navigate("/login"), 200000);

      return;
    }

    validateMagicLink(magicCode)
      .then((response) => {
        if (response.success) {
          setTimeout(() => {
            authenticate(response.data);
            console.log(response.data);
            navigate("/dashboard", { replace: true });
          }, 1000);
        } else {
          setErrorMessage(response.message || "Magic link expired or invalid.");
          setTimeout(() => navigate("/login"), 3000);
        }
      })
      .catch((error) => {
        setErrorMessage("Error validating Magic Link.");
        setTimeout(() => navigate("/login"), 3000);
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [navigate]);

  return <div>{isLoading ? <p>Verifying...</p> : <p>{errorMessage}</p>}</div>;
};

export default MagicLinkPage;
