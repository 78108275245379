import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  FormGroup,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
} from "reactstrap";
import classnames from "classnames";
import SnackbarComponent from "../../../../components/Snackbar/Snackbar";
import Loader from "../../../../components/Loader/Loader";
import { forgotPassword } from "../../../../actions/user-actions";

interface ForgotPasswordModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const ForgotPassword: React.FC<ForgotPasswordModalProps> = ({
  isOpen,
  toggle,
}) => {
  const [email, setEmail] = useState<string>("");
  const [focusedEmail, setFocusedEmail] = useState<boolean>(false);
  const [snack, setSnack] = useState({
    isOpen: false,
    severity: "success",
    message: "",
  });
  const [isLoading, setIsLoading] = useState(false);

  const handleForgotPassword = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!email) {
      setSnack({
        isOpen: true,
        severity: "error",
        message: "Please enter an email!",
      });
      return;
    }

    console.log("Forgot Password button clicked!", email);

    setIsLoading(true);

    setSnack({
      isOpen: true,
      severity: "info",
      message: "Sending Magic Link...",
    });

    try {
      const response = await forgotPassword(email);
      setTimeout(() => {
        setSnack({
          isOpen: true,
          severity: "success",
          message: "Magic Link sent to your email!",
        });

        toggle();
      }, 3000);
    } catch (error) {
      console.error("Error in forgot password:", error);
      setTimeout(() => {
        setSnack({
          isOpen: true,
          severity: "error",
          message:
            error.response?.data?.message ||
            "Failed to send Magic Link. Please try again.",
        });
      }, 3000);
    } finally {
      setIsLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnack((prev) => ({ ...prev, isOpen: false }));
  };

  return (
    <>
      <Modal isOpen={isOpen} toggle={toggle}>
        <ModalHeader toggle={toggle} className="bg-transparent pb-3">
          <div className="text-muted text-center mt-2 mb-3">
            <small>Enter your email to recover access.</small>
          </div>
        </ModalHeader>
        <ModalBody className="px-lg-5 py-lg-5">
          <Form role="form" onSubmit={handleForgotPassword}>
            <FormGroup
              className={classnames("mb-3", { focused: focusedEmail })}
            >
              <InputGroup className="input-group-merge input-group-alternative">
                <InputGroupAddon addonType="prepend">
                  <InputGroupText>
                    <i className="ni ni-email-83" />
                  </InputGroupText>
                </InputGroupAddon>
                <Input
                  placeholder="Email"
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  onFocus={() => setFocusedEmail(true)}
                  onBlur={() => setFocusedEmail(false)}
                />
              </InputGroup>
            </FormGroup>
            <div className="text-center">
              <Button
                className="my-4"
                color="info"
                type="submit"
                disabled={isLoading}
              >
                {isLoading ? (
                  <Loader height={20} width={20} color="white" />
                ) : (
                  "Send Magic Link"
                )}
              </Button>
            </div>
          </Form>
        </ModalBody>
      </Modal>

      {isLoading && <Loader height={50} width={50} color="info" />}

      <SnackbarComponent
        isOpen={snack.isOpen}
        severity={snack.severity}
        onClose={handleCloseSnackbar}
        message={snack.message}
      />
    </>
  );
};

export default ForgotPassword;
